import React from 'react';

import Layout from '../components/Layout';

import imageSidemountGozo2 from '../assets/images/video_sidemount_gozo2.jpg';
import imageSidemountGozo from '../assets/images/video_sidemount_gozo.jpg';
import imageHermenegildo from '../assets/images/video_hermenegildo_capelo.jpg';
import imageOliveira from '../assets/images/video_oliveira_e_carmo.jpg';
import imageMartelo from '../assets/images/video_martelo.jpg';
import imageGraviere from '../assets/images/video_la_graviere_du_fort.jpg';
import imageOceanRevival from '../assets/images/video_ocean_revival.jpg';
import imageGlassFishes from '../assets/images/video_glassfishes.jpg';
import imageRedSea2022 from '../assets/images/video_redsea_2022.jpg';

const IndexPage = () => (
        <Layout fullMenu>
            <article id="main">
                <header className="major">
                    <h2>Scuba Impressions</h2>
                    <p>Stay up-to-date and subscribe to my <a
                        href="https://www.youtube.com/@butimo"
                        target="_blank">YouTube</a> channel!</p>
                </header>

                <section id="youtube" className="wrapper style3">
                    <div className="inner">
                        <header>
                            <h2>Egypt Liveaboard Tour 2022</h2>
                            <p>
                                In 2022 I took part in an organized liveaboard in the Red Sea. This was organized by Achim
                                Schloeffel (<a href="http://www.is-expl.com" target="_blank">Innerspace Explorers</a>). My
                                buddy and roommate Andreas Krüger edited our video footage and created this awesome memory.
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/RzxsW7NNB9k" target="_blank">
                                    <img src={imageRedSea2022} alt="YouTube Video Red Sea trip 2022"
                                         width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Glassfishes at SS Carnatic</h2>
                            <p>
                                Meeting some schools of glassfishes at the SS Carnatic. This is one of four wrecks at the
                                famous dive site Abu Nuhas in the red sea.
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/QKvfBquvBto" target="_blank">
                                    <img src={imageGlassFishes} alt="YouTube Video Glassfishes at SS Carnatic"
                                         width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Diving Ocean Revival</h2>
                            <p>
                                Footage from my last dives at Ocean Revival – Algarve, Portugal. Together with Iris and
                                WeDive, Lagos. This was pretty good visibility which I didn't has often here. Great
                                diving... really enjoyed.
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/qLeOuh_r0eA" target="_blank">
                                    <img src={imageOceanRevival} alt="YouTube Video Diving Ocean Revival"
                                         width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Sidemount Gozo – Ras il-Ħobż</h2>
                            <p>
                                This was at day 3 of my sidemount essentials training with Vas Proud / Diver by Design. 2
                                hours dive at Ras il-Ħobż aka Middle Finger in June 2021.
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/PKWRqwKryOU" target="_blank">
                                    <img src={imageSidemountGozo2} alt="YouTube Video Sidemount Essentials Training"
                                         width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Sidemount Gozo – Blue Hole</h2>
                            <p>
                                Epic sidemount essentials training week with Vas Proud in Gozo, Malta. Dropped at Blue Hole
                                and dived back to Inland Sea, June 2021.
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/hIYzHC2Ph7c" target="_blank">
                                    <img src={imageSidemountGozo} alt="YouTube Video Sidemount Essentials Training"
                                         width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Wreck Diving</h2>
                            <p>
                                Diving Frigate Wreck at the Ocean Revival Park Portimão, Algarve, Portugal.
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/1dZ3U4eoe_Q" target="_blank">
                                    <img src={imageHermenegildo} alt="YouTube Video Wreck Hermenegildo Capelo" width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Wreck Diving</h2>
                            <p>
                                Diving Corvette Wreck at the Ocean Revival Park Portimão, Algarve, Portugal
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/Qd2WOdW4_bQ" target="_blank">
                                    <img src={imageOliveira} alt="YouTube Video Wreck Oliveira E Carmo" width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Shore Diving</h2>
                            <p>
                                Diving quarry pond (Baggersee) La Gravière Du Fort, Holtzheim, Alsace, France.
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/XHccy_tJDYg" target="_blank">
                                    <img src={imageGraviere} alt="YouTube Video Shore Diving" width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="inner">
                        <header>
                            <h2>Night Diving</h2>
                            <p>
                                This is some footage from my very first night dive. We found a lot of spider crabs and a
                                tiny cuttle fish. Sorry, footage was quite ugly. At least the spotted moray is a good shot.
                                Night diving is a great experience, something you should try!
                            </p>
                        </header>
                        <ul className="actions stacked">
                            <li>
                                <a href="https://youtu.be/cQRwlBD90E8" target="_blank">
                                    <img src={imageMartelo} alt="YouTube Video Night Diving Martelo" width="400"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>

            </article>
        </Layout>
    )
;

export default IndexPage;
